import TransactionRepository from "../repositories/TransactionRepository";
import ErrorService from "./ErrorService";

export default class TransactionService {
  constructor() {
    this.transactionRepository = new TransactionRepository();
  }

  async historyGet(payload) {
    const { limit, page } = payload;
    const params = {
      limit: limit || undefined,
      page: page || undefined,
      sort_field: "created_at",
      sort_dir: "desc",
    };

    try {
      return await this.transactionRepository.getHistory(params);
    } catch (error) {
      console.error("--> error:", error);
      ErrorService.handleError(error);
    }
  }
}
