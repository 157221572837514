import { defineStore } from "pinia";
import TransactionService from "../services/TransactionService";
import { ElMessage } from "element-plus";

export const useTransactionHistoryStore = defineStore("transactionHistory", {
  state: () => ({
    items: [],
    itemsHistory: [],
    limit: 5,
    page: 1,
    total: 0,
    isServerError: false,
    isLoading: false,
  }),
  actions: {
    async fetchDataHistory() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      const params = {
        limit: this.limit,
        page: this.page,
      };

      try {
        this.isLoading = true;
        const transactionService = new TransactionService();
        const response = await transactionService.historyGet(params);
        this.isLoading = false;
        this.isServerError = false;

        const { data } = response;
        this.itemsHistory = data.data;
        this.total = data.meta?.total || data.data.length || 0;

        return true;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.itemsHistory = [];

        this.isServerError = true;
        return false;
      }
    },
  },
  getters: {},
});
