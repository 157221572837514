<template>
  <main>
    <particle-background />

    <div class="rn-not-found-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="rn-not-found-wrapper">
              <h2 class="large-title">404</h2>
              <h3 class="title">Halama Tidak ditemukan!</h3>
              <p>pastikan Link / URL tepat ya...</p>
              <router-link to="/" class="btn btn-primary btn-large">
                Kembali ke Halaman Home
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ParticleBackground from "@/components/particles/ParticleBackground";

export default {
  name: "NotFound",
  components: { ParticleBackground },
};
</script>
