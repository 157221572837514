import ApiService from "@/api/ApiService";

const PATH_FIRST = "";
// const MOCK_URL = "http://localhost:3000/api/partner";

export default class AuthRepository {
  constructor() {
    this.apiService = ApiService;
  }

  async postLogin(formData) {
    return await this.apiService.post(`${PATH_FIRST}/login`, formData);
  }
  async getCurrent() {
    return await this.apiService.get(`${PATH_FIRST}/current`);
  }
  async postUpdateCurrent(formData) {
    return await this.apiService.post(`${PATH_FIRST}/current/update`, formData);
  }
  async postChangePasswordCurrent(formData) {
    return await this.apiService.post(
      `${PATH_FIRST}/current/change-password`,
      formData,
    );
  }
  async deleteLogout(formData) {
    return await this.apiService.delete(`${PATH_FIRST}/current/logout`);
  }
}
