<template>
  <layout>
    <breadcrumb title="Terms & Condition" current="Terms & Condition" />

    <div class="terms-condition-area rn-section-gapTop">
      <div class="container">
        <div class="row">
          <div class="offset-lg-2 col-lg-8">
            <div class="condition-wrapper">
              <h2>NFT Bidding Terms & Condition of Nuron</h2>
              <p>
                It could be a house, or a painting such as the Mona Lisa, which
                is one of a kind. You can take a photo of the painting or buy a
                print but there will only ever be one original painting. NFTs
                are "one-of-a-kind" assets in the digital world that can be
                bought and sold like any other piece of property, but which have
                no tangible form of their own.It could be a house, or a painting
                such as the Mona Lisa, which is one of a kind. You can take a
                photo of the painting or buy a print but there will only ever be
                one original painting. NFTs are "one-of-a-kind" assets in the
                digital world that can be bought and sold like any other piece
                of property, but which have no tangible form of their own.
              </p>
              <p>
                Poised to radically reconfigure the crypto-asset market,
                non-fungible tokens, or NFTs, are revolutionizing our conception
                of money and value, creating not just entirely new markets but
                even new economies that are able to scale globally and to
                discover value in undreamt places, relegating to oblivion fiat
                currencies and old ways of doing business..
              </p>
              <p>
                Because a Terms and Conditions agreement is the agreement where
                you inform the users of your website about the rules, terms and
                guidelines that they need to follow in order to use and access
                your website, a Terms and Conditions agreement has become
                extremely important.Because a Terms and Conditions agreement is
                the agreement where you inform the users of your website about
                the rules, terms and guidelines that they need to follow in
                order to use and access your website, a Terms and Conditions
                agreement has become extremely important.
              </p>

              <h2>Selling Condition Tearms & Condition of Nuron</h2>
              <p>
                Just kidding. The previous paragraph is a parody (if such is
                possible) of the hype that in the first half of has come to
                surround non-fungible tokens. Indeed, the hype has become so
                overpowering that it may even defy parody. Non-fungible tokens
                can have legitimacy, and I’ll discuss how that can be at the end
                of this article. But for now the overwhelming majority of what
                passes for NFTs is delusion, fueled by the hope of a quick
                return and the belief that something can be gotten for nothing
                (or virtually nothing).
              </p>
              <p>
                This is where I can explain and emphasize the “non-fungibility”
                property of NFTs. The main difference between NFTs and Bitcoins
                is the fact that Bitcoins are limited, and fungible (you can
                trade one Bitcoin with another and both have the same value and
                price). NFTs are unique but unlimited, and non-fungible (no two
                artworks are the same). While NFTs can appreciate in value (just
                like real estate), they cannot be interchanged for another NFT.
              </p>

              <h2>NFT Bidding Terms & Condition of Nuron</h2>
              <p>
                Warren Buffett famously remarked in 2018 that Bitcoin (and by
                implication all cryptocurrencies) were “probably rat poison
                squared.” That seems unduly pessimistic given that
                cryptocurrencies provide a way of securely moving around things
                that at least look like currency and that in some locales are
                actually being used like currency. In Venezuela, for instance,
                Bitcoin provides one way around the country’s corrupt central
                bank and the hyperinflation it has created. And in early June of
                , the El Salvador government even approved Bitcoin as legal
                tender.
              </p>
              <p>
                While not directly related to NFTs, it’s important to mention
                some properties of money. Among many properties, money has to be
                fungible (one unit is viewed as interchangeable as another), and
                divisible (can be divided into smaller units of value). NFTs are
                not fungible nor (easily) divisible. For example, a single
                dollar is easily convertible into four quarters or ten dimes,
                but currently you cannot divide one NFT (although the blockchain
                technology behind may allow it in future).
              </p>
              <p>
                Because a Terms and Conditions agreement is the agreement where
                you inform the users of your website about the rules, terms and
                guidelines that they need to follow in order to use and access
                your website, a Terms and Conditions agreement has become
                extremely important.Because a Terms and Conditions agreement is
                the agreement where you inform the users of your website about
                the rules, terms and guidelines that they need to follow in
                order to use and access your website, a Terms and Conditions
                agreement has become extremely important.
              </p>

              <h2>Buying NFT's Terms & Condition of Nuron</h2>
              <p>
                The importance of NFTs lies in providing the ability to securely
                value, purchase and exchange digital art using a digital ledger.
                NFTs started in online gaming, later with Nike’s patenting of
                its authenticity (CryptoKicks) and then by the famous Christie’s
                auction embracing NFT valuation of a digital art piece. NFTs are
                commonly created by uploading files, such as digital artwork, to
                an auction market. Just like any other form of art, NFTs are not
                mutually interchangeable, making them more like “collectible”
                items.
              </p>
              <p>
                Because a Terms and Conditions agreement is the agreement where
                you inform the users of your website about the rules, terms and
                guidelines that they need to follow in order to use and access
                your website, a Terms and Conditions agreement has become
                extremely important.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt--50">
          <div class="offset-lg-2 col-lg-8">
            <router-link to="#" class="btn btn-primary mr--15"
              >Accept</router-link
            >
            <router-link to="#" class="btn btn-primary-alta"
              >Decline</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import Layout from "@/components/layouts/Layout";

export default {
  name: "TermsCondition",
  components: { Layout, Breadcrumb },
};
</script>
