<template>
  <div class="rn-upcoming-area rn-section-gap" ref="scrollTarget">
    <div class="container">
      <div :class="[`row`]">
        <div class="col-12">
          <div class="table-title-area d-flex">
            <i class="feather-calendar" />
            <h3>List History Saldo</h3>
          </div>

          <div class="box-table table-responsive">
            <table class="table upcoming-projects">
              <thead>
                <tr>
                  <th style="width: 100px">
                    <span class="text-capitalize">No</span>
                  </th>
                  <template
                    v-for="(th, thIndex) in ['Nominal', 'Tipe', 'Tanggal']"
                    :key="`th-${thIndex}`"
                  >
                    <th>
                      <span class="text-capitalize">{{ th }}</span>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr v-if="transactionHistoryStore.isLoading">
                  <td colspan="4">
                    <span class="">Sedang Memuat</span>
                  </td>
                </tr>
                <tr
                  v-else-if="
                    !transactionHistoryStore.isLoading &&
                    !transactionHistoryStore.itemsHistory.length &&
                    transactionHistoryStore.isServerError
                  "
                >
                  <td colspan="4">
                    <span class="">DATA GAGAL DIAMBIL</span>
                  </td>
                </tr>
                <tr
                  v-else-if="
                    !transactionHistoryStore.isLoading &&
                    !transactionHistoryStore.itemsHistory.length
                  "
                >
                  <td colspan="4">
                    <span class="">Data Tidak ditemukan</span>
                  </td>
                </tr>
                <tr
                  :class="{ 'color-light': rowIndex % 2 === 0 }"
                  v-for="(
                    row, rowIndex
                  ) in transactionHistoryStore.itemsHistory"
                  :key="`row-${rowIndex}`"
                >
                  <td>
                    <span>{{ rowIndex + 1 }}</span>
                  </td>
                  <td>
                    <span class="color-info">{{
                      formatNumber(row.transaction_amount)
                    }}</span>
                  </td>
                  <td>
                    <span v-if="row.type === 'IN'" class="color-green">IN</span>
                    <span v-else class="color-danger">OUT</span>
                  </td>
                  <td>
                    <span class="">{{
                      formatDate(row.created_at, "D MMMM YYYY [pukul] HH.mm")
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <nav class="pagination-wrapper" aria-label="Page navigation example">
        <ul :class="`pagination`">
          <li
            class="page-item"
            :class="{ disabled: transactionHistoryStore.page === 1 }"
            @click.prevent="setPage(transactionHistoryStore.page - 1)"
          >
            <a class="page-link" href=""> Previous </a>
          </li>
          <li
            class="page-item"
            v-for="n in totalPage"
            @click.prevent="setPage(n)"
          >
            <a
              :class="[
                `page-link`,
                { active: transactionHistoryStore.page === n },
              ]"
              href=""
            >
              {{ n }}
            </a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: transactionHistoryStore.page === totalPage }"
            @click.prevent="setPage(transactionHistoryStore.page + 1)"
          >
            <a class="page-link" href=""> Next </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { useTransactionHistoryStore } from "../stores/transactionHistoryStore";
import { formatDate } from "../utils/appProject";
import { computed, nextTick, onMounted, ref } from "vue";
import { useGlobalComposable } from "../composables/globalComposable";

const { formatNumber } = useGlobalComposable();

const transactionHistoryStore = useTransactionHistoryStore();
const scrollTarget = ref(null);

onMounted(() => {
  transactionHistoryStore.fetchDataHistory();
});

const pageStart = computed(() => {
  return (transactionHistoryStore.page - 1) * transactionHistoryStore.limit;
});
const totalPage = computed(() => {
  return Math.ceil(
    transactionHistoryStore.total / transactionHistoryStore.limit,
  );
});

const setPage = (idx) => {
  if (idx <= 0 || idx > totalPage.value) {
    return;
  }

  transactionHistoryStore.page = idx;
  transactionHistoryStore.fetchDataHistory();
  window.scrollTo(0, 0);

  // Scroll to the referenced section
  nextTick(() => {
    if (scrollTarget.value) {
      scrollTarget.value.scrollIntoView({ behavior: "smooth" });
    }
  });
};
</script>
