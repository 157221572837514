import { createRouter, createWebHistory } from "vue-router";
import HomePageOne from "../views/home/HomePageOne";
import Connect from "../views/Connect";
import About from "../views/About";
import Contact from "../views/Contact";
import Login from "../views/Login";
import ProfilePage from "../views/Profile.vue";
import ForgetPassword from "../views/ForgetPassword";
import PrivacyPolicy from "../views/PrivacyPolicy";
import Ranking from "../views/Ranking";
import NotFound from "../views/NotFound";
import Support from "../views/Support";
import TermsCondition from "../views/TermsCondition";
import ComingSoon from "../views/ComingSoon";
import Maintenance from "../views/Maintenance";
import { useAuthStore } from "@/stores/authStore";

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function redirectToDashboardIfNeeded(to, from, next) {
  const result = localStorage.getItem("partner-canteen");
  if (result) {
    const resultLs = JSON.parse(result);
    if (isEmptyObject(resultLs.user)) {
      next();
    } else {
      next({ name: "HomePage" });
    }
  } else {
    next();
  }
}

function checkRequirement(to, from, next) {
  const result = localStorage.getItem("partner-canteen");
  if (!result) {
    localStorage.clear();
    next({ name: "LoginPage" });
  } else {
    const authStore = useAuthStore();
    authStore.fetchCurrentuser();
    next();
  }
}

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: Login,
    meta: {
      title: "Login || Mitra",
    },
    beforeEnter: redirectToDashboardIfNeeded,
  },
  {
    path: "/",
    name: "HomePage",
    component: HomePageOne,
    meta: {
      title: "Home || Mitra",
    },
    beforeEnter: checkRequirement,
  },
  {
    path: "/profil",
    name: "ProfilPage",
    component: ProfilePage,
    meta: {
      title: "Profil || Mitra",
    },
    beforeEnter: checkRequirement,
  },
  {
    path: "/connect",
    name: "Connect",
    component: Connect,
    meta: {
      title: "Connect || Mitra",
    },
    beforeEnter: checkRequirement,
  },
  {
    path: "/settlement",
    name: "SettlementPage",
    component: Connect,
    meta: {
      title: "Settlement || Mitra",
    },
    beforeEnter: checkRequirement,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "About || Mitra",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact || Mitra",
    },
  },
  {
    path: "/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "Forget Password || Mitra",
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy || Mitra",
    },
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: Ranking,
    meta: {
      title: "Ranking || Mitra",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 || Mitra",
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      title: "Ranking || Mitra",
    },
  },
  {
    path: "/terms-condition",
    name: "TermsCondition",
    component: TermsCondition,
    meta: {
      title: "Terms & Condition || Mitra",
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: "Coming Soon || Mitra",
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    meta: {
      title: "Maintenance || Mitra",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
