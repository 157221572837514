<template>
  <ul class="mainmenu">
    <li
      v-for="(item, index) in navItems"
      :class="[
        item.hasDropdown ? 'has-droupdown has-menu-child-item' : '',
        item.megamenu ? 'with-megamenu' : '',
      ]"
      :key="`nav-item-${index}`"
    >
      <router-link :to="item.link" class="nav-link its_new">
        {{ item.title }}
      </router-link>
      <ul v-if="item.hasDropdown" class="submenu">
        <li
          v-for="(subMenuItem, subMenuIndex) in item.dropdownItems"
          :key="`sub-menu-item-${subMenuIndex}`"
        >
          <router-link :to="subMenuItem.link" :class="subMenuItem.class">
            {{ subMenuItem.title }}
            <i
              v-if="subMenuItem.class !== 'live-expo'"
              :class="`feather-${subMenuItem.icon}`"
            />
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      navItems: [
        {
          link: "/",
          title: "Home",
          hasDropdown: false,
          megamenu: false,
        },
      ],
    };
  },
};
</script>
