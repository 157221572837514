<template>
  <layout>
    <particle-background />

    <!-- Start Banner Area -->
    <div class="slider-one rn-section-gapTop">
      <div class="container">
        <div class="row row-reverce-sm align-items-center">
          <div class="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
            <h2
              class="title"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              {{ APP.name }} Digital Bisnis.
            </h2>
            <p
              class="slide-disc"
              data-sal-delay="300"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Bermitralah dengan salah satu pengecer terbesar di dunia untuk
              memamerkan produk Anda merek dan produk.
            </p>
            <div class="button-group">
              <router-link
                class="btn btn-large btn-primary"
                to="#"
                data-sal-delay="400"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                Marikita Mulai
              </router-link>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
            <div class="slider-thumbnail">
              <img
                :src="require(`@/assets/images/slider/slider-1.png`)"
                alt="Slider Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Banner Area -->

    <!-- Start Live Bidding Area -->
    <explore-live-carousel />
    <!-- End Live Bidding Area -->

    <!-- Start Service Area -->
    <service-style-one />
    <!-- End Service Area -->

    <!-- Top Seller Start -->
    <seller-style-one />
    <!-- Top Seller End -->

    <share-modal />

    <report-modal />
  </layout>
</template>

<script>
import Layout from "../../components/layouts/Layout";
import ExploreLiveCarousel from "@/components/explore/ExploreLiveCarousel";
import ServiceCard from "@/components/service/ServiceCard";
import ProductCard from "@/components/product/ProductCard";
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import ShareModal from "@/components/modal/ShareModal";
import ReportModal from "@/components/modal/ReportModal";
import ServiceStyleOne from "@/components/service/ServiceStyleOne";
import SellerStyleOne from "@/components/seller/SellerStyleOne";
import ParticleBackground from "@/components/particles/ParticleBackground";
import { APP } from "@/utils/appProject";

export default {
  name: "HomePageOne",
  computed: {
    APP() {
      return APP;
    },
  },
  components: {
    ParticleBackground,
    SellerStyleOne,
    ServiceStyleOne,
    ReportModal,
    ShareModal,
    ProductCard,
    ServiceCard,
    ExploreLiveCarousel,
    Layout,
  },
  mixins: [SalScrollAnimationMixin],
};
</script>
