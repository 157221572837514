<script setup></script>

<template>
  <div>
    <h5 class="card-title placeholder-glow">
      <span class="placeholder col-6"></span>
    </h5>
    <p class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
    </p>
    <div class="row">
      <div class="col"></div>
      <div class="col-4">
        <div class="display-5 card-title placeholder-glow">
          <span class="placeholder col-5"></span>
          <span class="col-1 d-inline-block" style="width: 10px"></span>
          <span class="placeholder col-6"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
