import { defineStore } from "pinia";
import { ElMessage, ElMessageBox } from 'element-plus'
import AuthService from "@/services/AuthService";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isLoading: false,
    isLoadingCurrent: false,
    isLoadingChangePassword: false,
    form: {
      id: null,
      name: "",
      email: "",
      password: "",
    },
    formErrors: {},
    formChangePasswordErrors: {},
    formChangePassword: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    user: {
      id: null,
      name: null,
      email: null,
      is_wallet_actived: null,
      created_at: null,
      updated_at: null,
      wallet: {
        wallet_id: null,
        user_id: null,
        balance: null,
        status: null,
        last_transaction_id: null,
      },
      roles: [],
      schools: [],
      pivot: null,
    },
    meta: {},
    token: null,
  }),
  actions: {
    async postProccessLogin() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoading = true;
        const authService = new AuthService();
        const response = await authService.loginPost(this.form);
        this.isLoading = false;
        const { data } = response;
        this.user = data.data;
        this.meta = JSON.parse(JSON.stringify(data.meta));
        this.token = this.meta.token || "";
        window.location = "/";
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          return false;
        }

        ElMessage.error("Gagal Masuk. Cek Kembali Email / Password");
        return false;
      }
    },

    async putUpdateCurrent() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoading = true;
        const authService = new AuthService();
        await authService.updateCurrentUserPost(this.form);
        this.isLoading = false;
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          return false;
        }

        ElMessage.error(error.error || "Merubah data profil Gagal");
        return false;
      }
    },

    async postChangePassword() {
      if (this.isLoadingChangePassword) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoadingChangePassword = true;
        const authService = new AuthService();
        await authService.changePasswordPost(this.formChangePassword);
        this.isLoadingChangePassword = false;
        return true;
      } catch (error) {
        this.isLoadingChangePassword = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          return false;
        }

        ElMessage.error(error.error || "Merubah password gagal");
        return false;
      }
    },

    async deleteTokenUser() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoading = true;
        const authService = new AuthService();
        await authService.logout();
        this.isLoading = false;
        this.user = {};
        this.token = "";
        return true;
      } catch (error) {
        this.isLoading = false;

        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          return false;
        }

        ElMessage.error("Gagal menghapus session anda!");
        return false;
      }
    },

    async fetchCurrentuser() {
      if (this.isLoadingCurrent) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoadingCurrent = true;
        const authService = new AuthService();
        const response = await authService.currentGet();
        this.isLoadingCurrent = false;
        const { data } = response;
        this.user = data.data;
        this.form = {
          id: data.data.id,
          name: data.data.name,
          email: data.data.email,
        };
        return true;
      } catch (error) {
        this.isLoadingCurrent = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          return false;
        }
        if (error.status === 401 || error.status === 403) {
          ElMessageBox.confirm(
            "Token tidak sesuai. Anda akan diarhkan ke Proses Login Ulang!",
            "Konfirmasi",
            {
              confirmButtonText: "OK",
              cancelButtonText: "login",
              type: "warning",
            },
          )
            .then(() => {
              localStorage.clear()
            })
            .catch(() => {
              localStorage.clear()
            });
          return false;
        }

        return false;
      }
    },
  },
  getters: {
    userNameGetter(state) {
      return state.user?.name || "-";
    },
    walletBalanceGetter(state) {
      return state.user?.wallet?.balance || 0;
    },
    userRoleStringGetters(state) {
      try {
        return state.user.roles.map((item) => item.name).join(", ");
      } catch (e) {
        return "";
      }
    },
    sortRoleGetters() {
      try {
        const phrase = "Partner Canteen";
        return phrase
          .split(" ")
          .map((word) => word[0])
          .join("");
      } catch (e) {
        console.log(e);
        return "";
      }
    },
  },

  // Tambahkan konfigurasi persisted state
  persist: {
    enabled: true,
    key: "partner-canteen",
    paths: ["user", "meta", "token"],
  },
});
