import AuthRepository from "@/repositories/AuthRepository";
import ErrorService from "@/services/ErrorService";

export default class WebschoolService {
  constructor() {
    this.authRepository = new AuthRepository();
  }

  async currentGet() {
    try {
      return await this.authRepository.getCurrent();
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }

  async loginPost(payload) {
    const { email, password } = payload;
    if (!email || !password) {
      throw new Error("Client request not complete");
    }

    const formData = {
      email: email.substr(0, 255),
      password: password.substr(0, 100),
    };

    try {
      return await this.authRepository.postLogin(formData);
    } catch (error) {
      console.error("--> error:", error);
      ErrorService.handleError(error);
      throw error;
    }
  }

  async updateCurrentUserPost(payload) {
    const { email, name } = payload;
    if (!email || !name) {
      throw new Error("Client request not complete");
    }

    const formData = {
      email: email.substr(0, 255),
      name: name.substr(0, 100),
    };

    try {
      return await this.authRepository.postUpdateCurrent(formData);
    } catch (error) {
      console.error("--> error:", error);
      ErrorService.handleError(error);
    }
  }

  async changePasswordPost(payload) {
    const { oldPassword, newPassword } = payload;
    if (!oldPassword || !newPassword) {
      throw new Error("Client request not complete");
    }

    const formData = {
      old_password: oldPassword.substr(0, 100),
      new_password: newPassword.substr(0, 100),
    };

    try {
      return await this.authRepository.postChangePasswordCurrent(formData);
    } catch (error) {
      console.error("--> error:", error);
      ErrorService.handleError(error);
    }
  }

  async logoutDelete() {
    try {
      return await this.authRepository.deleteLogout();
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }
}
