import ApiService from "@/api/ApiService";

const PATH_FIRST = "bank-account";
// const MOCK_URL = "http://localhost:3000/api/partner";

export default class AccountBankRepository {
  constructor() {
    this.apiService = ApiService;
  }

  async getListBank() {
    return await this.apiService.get(`/bank`);
  }

  async getAccountBank(params) {
    return await this.apiService.get(`/${PATH_FIRST}`, { params });
  }
  async getAccountBankById(id) {
    return await this.apiService.get(`/${PATH_FIRST}/${id}`);
  }
  async postAccountBank(formData) {
    return await this.apiService.post(`/${PATH_FIRST}`, formData);
  }
  async putAccountBankById(id, formFata) {
    return await this.apiService.put(`/${PATH_FIRST}/${id}`, formFata);
  }
  async deleteAccountBankById(id) {
    return await this.apiService.delete(`/${PATH_FIRST}/${id}`);
  }
}
