import { defineStore } from "pinia";
import AccountBankService from "@/services/AccountBankService";
import { ElMessage } from "element-plus";

export const useAccountBank = defineStore("accountBank", {
  state: () => ({
    dataBank: {
      isLoading: false,
      items: [],
      accountBankAll: [],
      isServerError: false,
    },
    items: [],
    isServerError: false,
    isLoading: false,
    isLoadingFormData: false,
    filterIsApproved: false,
    formData: {
      id: null,
      bankId: "",
      accountHolder: "",
      accountNumber: "",
    },
    formDataErrors: {},
  }),
  actions: {
    async fetchAllAccountBank() {
      try {
        const params = {
          limit: 1000,
          page: 1,
        };

        const accountBankService = new AccountBankService();

        // Fetch both approved and not approved data in parallel
        const [approvedResponse, notApprovedResponse] = await Promise.all([
          accountBankService.dataGet({ ...params, is_approved: true }),
          accountBankService.dataGet({ ...params, is_approved: false }),
        ]);

        // Combine the data from both responses
        this.dataBank.accountBankAll = [
          ...approvedResponse.data.data,
          ...notApprovedResponse.data.data,
        ];

        return true;
      } catch (error) {
        return false;
      }
    },

    async fetchListBank() {
      const { isLoading } = this.dataBank;
      if (isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.dataBank.isLoading = true;
        const accountBankService = new AccountBankService();
        const response = await accountBankService.listBankGet();
        this.dataBank.isLoading = false;
        this.dataBank.isServerError = false;

        const { data } = response;
        this.dataBank.items = data.data;
        return true;
      } catch (error) {
        this.dataBank.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          this.dataBank.isServerError = true;
          return false;
        }

        return false;
      }
    },

    async fetchAccountBank() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      const params = {
        is_approved: this.filterIsApproved,
      };

      try {
        this.isLoading = true;
        const accountBankService = new AccountBankService();
        const response = await accountBankService.dataGet(params);
        this.isLoading = false;
        this.isServerError = false;

        const { data } = response;
        this.items = data.data;
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          this.isServerError = true;
          return false;
        }

        return false;
      }
    },

    async postDataAccountBank() {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      // check status pending
      if (this.dataBank.accountBankAll.find((item) => !item.is_approved)) {
        ElMessage.warning({
          message:
            "Tidak dapat menambahkan data, Hingga Pengajuan awal disetujui!",
        });
        return false;
      }

      try {
        this.isLoading = true;
        const accountBankService = new AccountBankService();
        await accountBankService.dataPost(this.formData);
        this.isLoading = false;
        this.isServerError = false;

        ElMessage.success({
          message: "Berhasil diproses.",
        });
        this.fetchAccountBank();
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          this.isServerError = true;
          return false;
        }

        ElMessage.error({
          message: error.error || "Gagal diproses!",
        });
        return false;
      }
    },

    async putDataAccountBank(id) {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoading = true;
        const accountBankService = new AccountBankService();
        await accountBankService.dataByIdPut(id, this.formData);
        this.isLoading = false;
        this.isServerError = false;

        ElMessage.success({
          message: "Berhasil diproses.",
        });
        this.fetchAccountBank();
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          this.isServerError = true;
          return false;
        }

        ElMessage.error({
          message: error.error || "Gagal diproses!",
        });
        return false;
      }
    },

    async deleteAccountBank(id) {
      if (this.isLoading) {
        console.warn("duplicate call server");
        return false;
      }

      try {
        this.isLoading = true;
        const accountBankService = new AccountBankService();
        await accountBankService.dataDelete(id);
        this.isLoading = false;
        this.isServerError = false;

        ElMessage.success({
          message: "Berhasil diproses.",
        });
        this.fetchAccountBank();
        return true;
      } catch (error) {
        this.isLoading = false;
        if (!error.status) {
          return false;
        }
        if (error.status >= 500) {
          this.isServerError = true;
          return false;
        }

        ElMessage.error({
          message: error.error || "Gagal diproses!",
        });
        return false;
      }
    },
  },
  getters: {},
});
