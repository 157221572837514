import AccountBankRepository from "@/repositories/AccountBankRepository";
import ErrorService from "./ErrorService";

export default class AccountBankService {
  constructor() {
    this.accountBankRepository = new AccountBankRepository();
  }

  async listBankGet(payload) {
    try {
      return await this.accountBankRepository.getListBank();
    } catch (error) {
      ErrorService.handleError(error);
      throw error;
    }
  }

  async dataGet(payload) {
    const { filterQ, limit, page, is_approved } = payload;
    const params = {
      q: filterQ || undefined,
      limit: limit || undefined,
      page: page || undefined,
      is_approved: is_approved || false,
      sort_field: "account_holder",
      sort_dir: "asc",
    };

    try {
      return await this.accountBankRepository.getAccountBank(params);
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }

  async dataByIdGet(id) {
    if (!id) {
      throw new Error("Client id required");
    }

    try {
      return await this.accountBankRepository.getAccountBankById(id);
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }

  async dataPost(payload) {
    const { bankId, accountNumber, accountHolder } = payload;
    if (!bankId || !accountNumber || !accountHolder) {
      throw new Error("Client request not complete");
    }

    const formData = {
      bank_id: bankId,
      account_number: accountNumber.substr(0, 100),
      account_holder: accountHolder.substr(0, 100),
    };

    try {
      return await this.accountBankRepository.postAccountBank(formData);
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }

  async dataByIdPut(id, payload) {
    console.log(payload);
    if (!id) {
      throw new Error("Client id required");
    }

    const { bankId, accountNumber, accountHolder } = payload;

    if (!bankId || !accountNumber || !accountHolder) {
      throw new Error("Client request not complete");
    }

    const formData = {
      bank_id: bankId,
      account_number: accountNumber.substr(0, 100),
      account_holder: accountHolder.substr(0, 100),
    };

    try {
      return await this.accountBankRepository.putAccountBankById(id, formData);
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }

  async dataDelete(id) {
    if (!id) {
      throw new Error("Client id required");
    }

    try {
      return await this.accountBankRepository.deleteAccountBankById(id);
    } catch (error) {
      console.error("--> error:", error);
      throw error;
    }
  }
}
