<script>
import Layout from "@/components/layouts/Layout";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import ModalLogOut from "@/views/ModalLogOut.vue";
import { useAuthStore } from "@/stores/authStore";
import { z, ZodError } from "zod";
import { emailSchema, nameSchema } from "@/schemas/formSchema";
import { getCurrentInstance, onMounted } from "vue";
import FormSkelton from "@/components/skelton/FormSkelton.vue";
import { UserValidation } from "@/validation/userValidation";
import ProfileAccountBankForm from "./ProfileAccountBankForm.vue";
import ProfileAccountBankList from "./ProfileAccountBankList.vue";

export default {
  name: "Profile",
  components: {
    ProfileAccountBankList,
    ProfileAccountBankForm,
    FormSkelton,
    ModalLogOut,
    Breadcrumb,
    Layout,
  },
  setup() {
    const authStore = useAuthStore();
    const { proxy } = getCurrentInstance();

    const schemaForm = z.object({
      name: nameSchema,
      email: emailSchema,
    });

    const onProccessUpdateCurrent = async () => {
      const loadingInstance = proxy.$loadingIntern.show({
        target: ".preloading_updatecurrent",
      });
      try {
        schemaForm.parse(authStore.form);
        authStore.formErrors = {};
        await authStore.putUpdateCurrent();
        proxy.$loadingIntern.hide(loadingInstance);
      } catch (e) {
        proxy.$loadingIntern.hide(loadingInstance);
        if (e instanceof ZodError) {
          const zodErrors = e.errors.reduce((acc, error) => {
            acc[error.path[0]] = error.message;
            return acc;
          }, {});
          Object.assign(authStore.formErrors, zodErrors);
          authStore.formErrors = zodErrors;
        }
      }
    };

    const onProccessChangePassword = async () => {
      const loadingInstance = proxy.$loadingIntern.show({
        target: ".preloading_changepassword",
      });
      try {
        UserValidation.CHANGE_PASSWORD.parse(authStore.formChangePassword);
        authStore.formChangePasswordErrors = {};
        console.log("ok");
        await authStore.postChangePassword();
        proxy.$loadingIntern.hide(loadingInstance);
      } catch (e) {
        console.log(e);
        proxy.$loadingIntern.hide(loadingInstance);
        if (e instanceof ZodError) {
          const zodErrors = e.errors.reduce((acc, error) => {
            acc[error.path[0]] = error.message;
            return acc;
          }, {});
          Object.assign(authStore.formChangePasswordErrors, zodErrors);
          authStore.formChangePasswordErrors = zodErrors;
        }
      }
    };

    return {
      authStore,
      onProccessUpdateCurrent,
      onProccessChangePassword,
    };
  },
  data() {
    return {
      currPage: 1,
      countOfPage: 1,
      rankingList: [
        {
          id: 1,
          product: {
            title: "Secure 25",
            slug: "/collection",
            image: {
              src: require(`@/assets/images/portfolio/portfolio-05.jpg`),
            },
          },
          floor_price: "33.02",
          owners: "3k",
          items: "10k",
        },
        {
          id: 44,
          product: {
            title: "Secure 25",
            slug: "/collection",
            image: {
              src: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            },
          },
          volume: "20,50,000",
          one_day_average: {
            charge: "310.63%",
            status: "+",
          },
          seven_days_average: {
            charge: "62.21%",
            status: "-",
          },
          floor_price: "33.02",
          owners: "2.5k",
          items: "30k",
        },
        {
          id: 55,
          product: {
            title: "Secure 25",
            slug: "/collection",
            image: {
              src: require(`@/assets/images/portfolio/portfolio-05.jpg`),
            },
          },
          volume: "9,50,000",
          one_day_average: {
            charge: "310.63%",
            status: "-",
          },
          seven_days_average: {
            charge: "62.21%",
            status: "+",
          },
          floor_price: "300.02",
          owners: "33k",
          items: "50k",
        },
      ],
    };
  },
  computed: {
    isActiveComputed() {
      const { name, email } = this.authStore.form;
      return (
        name === this.authStore.user.name && email === this.authStore.user.email
      );
    },
    filteredRows() {
      return this.rankingList;
    },
    pageStart() {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage() {
      return Math.ceil(this.filteredRows.length / this.countOfPage);
    },
  },
};
</script>

<template>
  <layout>
    <breadcrumb title="Edit Profil" current="Edit Profil" />

    <div class="edit-profile-area rn-section-gapTop">
      <div class="container">
        <div
          class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0"
        >
          <div
            class="col-12 d-flex justify-content-between mb--30 align-items-center"
          >
            <h4 class="title-left">Edit Profil Kamu</h4>
          </div>
        </div>
        <div
          class="row plr--70 padding-control-edit-wrapper pl_md--0 pr_md--0 pl_sm--0 pr_sm--0"
        >
          <div class="col-lg-3 col-md-3 col-sm-12">
            <nav class="left-nav rbt-sticky-top-adjust-five">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-home-tabs"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-homes"
                  type="button"
                  role="tab"
                  aria-controls="nav-homes"
                  aria-selected="false"
                >
                  <i class="feather-user" /> Personal information
                </button>
                <button
                  class="nav-link"
                  id="nav-change-password-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-change-password"
                  type="button"
                  role="tab"
                  aria-controls="nav-change-password"
                  aria-selected="false"
                >
                  <i class="feather-unlock" /> Change Password
                </button>
                <button
                  class="nav-link"
                  id="nav-rekening-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-rekening"
                  type="button"
                  role="tab"
                  aria-controls="nav-rekening"
                  aria-selected="false"
                >
                  <i class="feather-credit-card" /> Account Bank
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modalLogOut"
                  class="nav-link"
                  id="nav-logout-tab"
                  type="button"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  <i class="feather-log-out" /> LogOut
                </button>
              </div>
            </nav>
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12 mt_sm--30">
            <div
              class="tab-content tab-content-edit-wrapepr"
              id="nav-tabContent"
            >
              <div
                class="tab-pane fade show active preloading_updatecurrent"
                id="nav-homes"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <FormSkelton v-if="authStore.isLoadingCurrent" />

                <div v-else class="nuron-information">
                  <div class="profile-form-wrapper">
                    <div class="name-area mb--15">
                      <div class="first-name">
                        <label for="contact-name" class="form-label"
                          >Full Name</label
                        >
                        <input
                          v-model="authStore.form.name"
                          id="contact-name"
                          type="text"
                        />
                        <small
                          class="text-danger"
                          v-if="authStore.formErrors.name"
                          >{{ authStore.formErrors.name }}</small
                        >
                      </div>
                    </div>
                    <div class="email-area">
                      <label for="Email" class="form-label"
                        >Edit Your Email</label
                      >
                      <input
                        v-model="authStore.form.email"
                        id="Email"
                        type="email"
                      />
                      <small
                        class="text-danger"
                        v-if="authStore.formErrors.email"
                        >{{ authStore.formErrors.email }}</small
                      >
                    </div>
                  </div>
                  <div class="input-two-wrapepr-prifile">
                    <div class="role-area mt--15">
                      <label for="Role" class="form-label mb--10">Level</label>
                      <input
                        :readonly="true"
                        :value="authStore.userRoleStringGetters"
                        id="Role"
                        type="text"
                      />
                    </div>
                  </div>

                  <!-- btn submit -->
                  <div class="button-area save-btn-edit">
                    <a
                      href="#"
                      class="btn btn-primary-alta mr--15"
                      @click="alert('Cancel Edit Profile?')"
                    >
                      Batal
                    </a>
                    <button
                      :disabled="isActiveComputed"
                      class="btn btn-primary"
                      @click="onProccessUpdateCurrent"
                    >
                      Proses
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade preloading_changepassword"
                id="nav-change-password"
                role="tabpanel"
                aria-labelledby="nav-change-password-tab"
              >
                <div class="nuron-information">
                  <div class="condition">
                    <h5 class="title">Buat Password Baru</h5>
                    <p class="condition">
                      Kata sandi adalah bagian penting dari keamanan informasi
                      dan jaringan. Kata sandi berfungsi untuk melindungi akun
                      pengguna, tetapi kata sandi yang dipilih dengan buruk,
                      jika dibobol, dapat membahayakan seluruh jaringan.
                    </p>
                    <hr />
                    <div class="email-area">
                      <label for="Email2" class="form-label">Email Anda</label>
                      <input
                        :value="authStore.form.email"
                        :readonly="true"
                        id="Email2"
                        type="email"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="input-two-wrapper mt--15">
                    <div class="old-password half-wid">
                      <label for="oldPass" class="form-label"
                        >Password Lama</label
                      >
                      <input
                        v-model="authStore.formChangePassword.oldPassword"
                        id="oldPass"
                        type="password"
                      />
                      <small
                        class="text-danger"
                        v-if="authStore.formChangePasswordErrors.oldPassword"
                        >{{
                          authStore.formChangePasswordErrors.oldPassword
                        }}</small
                      >
                    </div>
                    <div class="new-password half-wid">
                      <label for="NewPass" class="form-label"
                        >Password Baru</label
                      >
                      <input
                        v-model="authStore.formChangePassword.newPassword"
                        id="NewPass"
                        type="password"
                      />
                      <small
                        class="text-danger"
                        v-if="authStore.formChangePasswordErrors.newPassword"
                        >{{
                          authStore.formChangePasswordErrors.newPassword
                        }}</small
                      >
                    </div>
                  </div>
                  <div class="email-area mt--15">
                    <label for="rePass" class="form-label"
                      >Konfirmasi Password Baru</label
                    >
                    <input
                      v-model="authStore.formChangePassword.confirmPassword"
                      id="rePass"
                      type="password"
                    />
                    <small
                      class="text-danger"
                      v-if="authStore.formChangePasswordErrors.confirmPassword"
                      >{{
                        authStore.formChangePasswordErrors.confirmPassword
                      }}</small
                    >
                  </div>
                  <button
                    class="btn btn-primary save-btn-edit"
                    @click="onProccessChangePassword"
                  >
                    Proses
                  </button>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="nav-rekening"
                role="tabpanel"
                aria-labelledby="nav-rekening-tab"
              >
                <div class="nuron-information">
                  <ProfileAccountBankList />
                  <ProfileAccountBankForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalLogOut />
  </layout>
</template>

<style scoped></style>
