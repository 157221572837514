// schemas/emailSchema.js
import { z } from "zod";

export const nameSchema = z
  .string()
  .min(3, "minimal 3karakter")
  .max(100, "terlalu panjang");
export const emailSchema = z
  .string()
  .email("tidak sesuai")
  .max(255, "terlalu panjang")
  .min(5, "minimal 5karakter");
export const passwordSchema = z
  .string()
  .min(5, "minimal 5karakter")
  .max(100, "terlalu panjang");
