<script setup>
import { ZodError } from "zod";
import { getCurrentInstance, onMounted } from "vue";
import { useAccountBank } from "@/stores/accountBankStore";
import InputMessageValidation from "../components/InputMessageValidation.vue";
import { UserValidation } from "@/validation/userValidation";

const accountBankStore = useAccountBank();
const { proxy } = getCurrentInstance();

const onCancelFormData = () => {
  accountBankStore.formData = {
    id: "",
    bank_id: null,
    accountHolder: null,
    accountNumber: null,
  };
};

const onProccessCreate = async () => {
  const loadingInstance = proxy.$loadingIntern.show({
    target: ".preloading_account_bank_form",
  });

  try {
    UserValidation.ACCOUNT_BANK.parse(accountBankStore.formData);
    accountBankStore.formDataErrors = {};

    // logic to update data
    if (accountBankStore.formData.id) {
      await accountBankStore.putDataAccountBank(accountBankStore.formData.id);
    } else {
      await accountBankStore.postDataAccountBank();
    }

    proxy.$loadingIntern.hide(loadingInstance);
  } catch (e) {
    console.log("---", e);
    proxy.$loadingIntern.hide(loadingInstance);

    if (e instanceof ZodError) {
      const zodErrors = e.errors.reduce((acc, error) => {
        acc[error.path[0]] = error.message;
        return acc;
      }, {});
      Object.assign(accountBankStore.formDataErrors, zodErrors);
      accountBankStore.formDataErrors = zodErrors;
    }
  }
};

onMounted(() => {
  if (!accountBankStore.dataBank.items.length) {
    accountBankStore.fetchListBank();
  }
});
</script>

<template>
  <div class="wrapper-form-rekening preloading_account_bank_form">
    <!--form pengajuan-->
    <h5 class="title">
      Form Pengajuan Nomor Rekening
      <span v-if="accountBankStore.formData.id"
        >Ubah ID {{ accountBankStore.formData.id }}</span
      >
    </h5>
    <div class="input-two-wrapepr-prifile">
      <div class="role-area mt--15 w-100">
        <label for="Role" class="form-label mb--10">Nama Pemilik Bank</label>
        <input
          v-model="accountBankStore.formData.accountHolder"
          id="Role"
          type="text"
        />
        <InputMessageValidation
          :value="accountBankStore.formDataErrors.accountHolder"
        />
      </div>
      <div class="d-flex flex-column w-100">
        <select
          v-model="accountBankStore.formData.bankId"
          class="profile-edit-select w-100"
        >
          <option value="">Pilih Bank</option>
          <option
            v-for="(item, i) in accountBankStore.dataBank.items"
            :key="i"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <InputMessageValidation
          class="ml-5"
          :value="accountBankStore.formDataErrors.bankId"
        />
      </div>
    </div>
    <div class="norek-area mt--15">
      <label for="norekening" class="form-label">Nomor Rekening</label>
      <input
        v-model="accountBankStore.formData.accountNumber"
        id="norekening"
        type="number"
      />
      <InputMessageValidation
        :value="accountBankStore.formDataErrors.accountNumber"
      />
    </div>

    <!-- btn submit -->
    <div class="button-area save-btn-edit">
      <button
        @click="onCancelFormData"
        class="btn btn-primary-alta save-btn-edit mr--15"
      >
        Batal
      </button>
      <button @click="onProccessCreate" class="btn btn-primary save-btn-edit">
        Proses
      </button>
    </div>
  </div>
</template>

<style scoped></style>
