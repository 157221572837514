<script setup>
import { getCurrentInstance, onMounted, watch } from "vue";
import { useAccountBank } from "../stores/accountBankStore";
import { ElMessageBox } from "element-plus";

const accountBankStore = useAccountBank();
const { proxy } = getCurrentInstance();

onMounted(() => {
  accountBankStore.fetchAccountBank();
  if (!accountBankStore.dataBank.accountBankAll.length) {
    accountBankStore.fetchAllAccountBank();
  }
});

const onFilterList = (value) => {
  if (!accountBankStore.isLoading) {
    accountBankStore.filterIsApproved = value;
  }
};

const onUpdate = (item) => {
  const itemConvert = JSON.parse(JSON.stringify(item));
  const { id, bank, bank_account_holder, bank_account_number } = itemConvert;
  accountBankStore.formData = {
    id: id,
    bankId: bank.id,
    accountHolder: bank_account_holder,
    accountNumber: bank_account_number,
  };
};

const onDelete = (id) => {
  if (!accountBankStore.isLoading) {
    let loadingInstance = null;
    ElMessageBox.confirm(
      "Data akan dihapus dari pengajuan Rekening?",
      "Konfirmasi",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      },
    )
      .then(async () => {
        loadingInstance = proxy.$loadingIntern.show({
          target: ".preloading_account_bank_list",
        });
        await accountBankStore.deleteAccountBank(id);
        proxy.$loadingIntern.hide(loadingInstance);
      })
      .catch(() => {
        proxy.$loadingIntern.hide(loadingInstance);
      });
  }
};

watch(
  () => accountBankStore.filterIsApproved,
  () => {
    accountBankStore.fetchAccountBank();
  },
);
</script>

<template>
  <div class="condition preloading_account_bank_list">
    <h5 class="title">Data Rekening</h5>
    <p class="condition">
      list data rekening, tambahkan nomor rekening anda lalu tunggu hingga
      proses setujui oleh admin, atau anda bisa melakukan tanya melalui
      whatsapp.
    </p>
    <hr />

    <div class="tagcloud mb-4">
      <a
        href="javascript:void(0);"
        :class="{ active: accountBankStore.filterIsApproved }"
        @click="onFilterList(true)"
      >
        Success
      </a>
      <a
        href="javascript:void(0);"
        :class="{ active: !accountBankStore.filterIsApproved }"
        @click="onFilterList(false)"
      >
        Pending
      </a>
    </div>

    <div class="box-table table-responsive">
      <table class="table upcoming-projects">
        <thead>
          <tr>
            <th style="width: 5%" class="">No</th>
            <th style="width: 50%" class="">Data Rekening</th>
            <th style="width: 10%" class="">Status</th>
            <th style="width: 5%" class="">Aksi</th>
          </tr>
        </thead>
        <tbody class="ranking">
          <tr v-if="!accountBankStore.items.length">
            <td colspan="4">Belum Terdapat Data</td>
          </tr>
          <tr v-else-if="accountBankStore.isLoading">
            <td colspan="4">
              <h3>Sedang Memuat</h3>
            </td>
          </tr>
          <tr
            :class="{ 'color-light': rowIndex % 2 === 0 }"
            v-for="(row, rowIndex) in accountBankStore.items"
            :key="`upcoming-project-${rowIndex}`"
          >
            <td>{{ rowIndex + 1 }}</td>
            <td>
              (#{{ row.id }}) {{ row.bank.name }} <br />
              {{ row.bank_account_holder }} |
              {{ row.bank_account_number }}
            </td>
            <td>
              <span
                :class="{
                  'bg-primary': row.is_approved,
                  'bg-warning': !row.is_approved,
                }"
                class="rn-badge-card"
                style="color: black"
              >
                {{ row.is_approved ? "APPROVE" : "PENDING" }}
              </span>
            </td>
            <td>
              <div
                v-if="!row.is_approved"
                class="btn-group rounded-5 btn-group-sm"
                role="group"
                aria-label="Basic outlined example"
              >
                <button
                  @click="onUpdate(row)"
                  type="button"
                  class="rn-btn btn-outline-primary"
                >
                  edit
                </button>
                <button
                  @click="onDelete(row.id)"
                  type="button"
                  class="rn-btn btn-outline-danger"
                >
                  hapus
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped></style>
