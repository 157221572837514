// plugins/loadingPlugin.js
import { ElLoading } from "element-plus";

export default {
  install(app) {
    app.config.globalProperties.$loadingIntern = {
      show(options = {}) {
        const defaultOptions = {
          text: "Sedang diproses...",
          lock: true,
          fullscreen: false,
          target: ".preloading",
        };
        const mergedOptions = { ...defaultOptions, ...options };
        return ElLoading.service(mergedOptions);
      },
      hide(loadingInstance) {
        if (loadingInstance) {
          loadingInstance.close();
        }
      },
    };
  },
};
