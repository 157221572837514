import axios from "axios";

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

// Simplify the retrieval of token and adding it to the request headers
ApiService.interceptors.request.use(
  (config) => {
    const localData = JSON.parse(
      localStorage.getItem("partner-canteen") || "null",
    );
    const token = localData?.token ?? "";
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

// A separate function to handle global error responses
function handleError(error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        alert("Unauthorized, Lakukan login lagi ya...");
        localStorage.clear();
        window.location.href = "/";
        break;
    }

    const responseError = {
      code: error.code,
      status: error.response.status,
      error: error.response.data.error || error.response.data.message,
      errorList: error.response.data.errors,
    };
    if (error.response.status === 307) {
      responseError.data = error.response.data;
    }
    return Promise.reject(responseError);
  } else if (error.request) {
    console.log("Error with request:", error.request);
  }
  return Promise.reject(error);
}

// Applying the error handling function to the response interceptor
ApiService.interceptors.response.use(
  async (response) => {
    // Anda bisa melakukan pengecekan atau transformasi pada response di sini
    return response;
  },
  async (error) => {
    // Anda bisa menggunakan async/await di sini jika perlu
    return handleError(error);
  },
);

export default ApiService;
