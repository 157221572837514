// composables/dateTime.ts

import numeral from "numeral";

export function useGlobalComposable() {
  const formatNumber = (value) => {
    if (!value) {
      return 0;
    }
    return numeral(value).format("0,0");
  };

  return {
    formatNumber,
  };
}
