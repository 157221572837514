import ApiService from "@/api/ApiService";

const PATH_FIRST = "transaction";
// const MOCK_URL = "http://localhost:3000/api/partner";

export default class TransactionRepository {
  constructor() {
    this.apiService = ApiService;
  }

  async getHistory(params) {
    return await this.apiService.get(`/${PATH_FIRST}/history`, { params });
  }
}
