import moment from "../moment-locale-id";

export const APP = {
  name: "Mitra",
  nameUppercase: "MITRA",
  nameLowercase: "mitra",
  nameLocalStorage: "webmitra",
};

export function formatDate(value, formatType = "LL") {
  if (value) {
    return moment(String(value)).format(formatType);
  }
  return "";
}
