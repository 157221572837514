import { z } from "zod";
import { emailSchema } from "../schemas/formSchema";

export class UserValidation {
  static LOGIN = z.object({
    email: z
      .string()
      .email("tidak sesuai")
      .max(255, "terlalu panjang")
      .min(5, "minimal 5karakter"),
    password: z
      .string()
      .min(5, "minimal 5karakter")
      .max(100, "terlalu panjang"),
  });

  static CHANGE_PASSWORD = z
    .object({
      oldPassword: z
        .string()
        .min(5, "minimal 5karakter")
        .max(100, "terlalu panjang"),
      newPassword: z
        .string()
        .min(5, "minimal 5karakter")
        .max(100, "terlalu panjang"),
      confirmPassword: z
        .string()
        .min(5, "minimal 5karakter")
        .max(100, "terlalu panjang"),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: "Password baru dan konfirmasi password harus sama",
      path: ["confirmPassword"],
    });

  static ACCOUNT_BANK = z
    .object({
      bankId: z.any(),
      accountHolder: z
        .string()
        .min(5, "minimal 5karakter")
        .max(100, "terlalu panjang"),
      accountNumber: z
        .string()
        .min(5, "minimal 5karakter")
        .max(100, "terlalu panjang"),
    })
    .refine((data) => data.bankId !== "", {
      message: "Pilih salah satu",
      path: ["bankId"],
    });
}
