<script>
export default {
  name: "ModalLogOut",
  methods: {
    onLogOut() {
      localStorage.clear();
      window.location = "/login";
    },
  },
};
</script>

<template>
  <div
    class="modal fade rn-popup-modal"
    tabindex="-1"
    id="modalLogOut"
    aria-labelledby="modalLogOutLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Konfirmasi</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x" />
          </button>
        </div>
        <div class="modal-body">
          <p>Jika melakukan LogOut anda akan diarahkan ke Halaman LogIn</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Batal
          </button>
          <button @click="onLogOut" type="button" class="btn btn-primary">
            Ya, LogOut
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
