<script setup>
import { computed } from "vue";

const props = defineProps(["value"]);
const isTrue = computed(() => !!props.value);
</script>

<template>
  <small
    class="text-danger"
    :class="{
      'd-block': isTrue,
      'd-none': !isTrue,
    }"
  >
    {{ props.value }}
  </small>
</template>

<style scoped></style>
