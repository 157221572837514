<template>
  <main>
    <particle-background />

    <div class="login-area">
      <div class="container">
        <div class="row g-5 justify-content-center">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-wrapper-one preloading">
              <h4>Login</h4>
              <form @submit.prevent="onSubmitLogin" class="mb-5">
                <div class="mb-5">
                  <label for="exampleInputEmail1" class="form-label"
                    >Email address</label
                  >
                  <input
                    v-model="authStore.form.email"
                    required
                    type="email"
                    id="exampleInputEmail1"
                  />
                  <small class="text-danger" v-if="errors.email">{{
                    errors.email
                  }}</small>
                </div>
                <div class="mb-5">
                  <label for="exampleInputPassword1" class="form-label"
                    >Password</label
                  >
                  <input
                    v-model="authStore.form.password"
                    required
                    type="password"
                    id="exampleInputPassword1"
                  />
                  <small class="text-danger" v-if="errors.password">{{
                    errors.password
                  }}</small>
                </div>
                <button type="submit" class="btn btn-primary mr--15">
                  Log In
                </button>
              </form>

              <a href="https://wa.me/6289676041493" target="_blank" class=""
                ><small>Reset Password</small></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { getCurrentInstance, reactive } from "vue";
import ParticleBackground from "@/components/particles/ParticleBackground.vue";
import { useAuthStore } from "@/stores/authStore";
import { z, ZodError } from "zod";
import { emailSchema, passwordSchema } from "@/schemas/formSchema";

const authStore = useAuthStore();
const { proxy } = getCurrentInstance();
const errors = reactive({});

const schema = z.object({
  email: emailSchema,
  password: passwordSchema,
});

const onSubmitLogin = async () => {
  const loadingInstance = proxy.$loadingIntern.show();
  try {
    schema.parse(authStore.form);
    errors.value = {};
    await authStore.postProccessLogin();
    loadingInstance.close();
  } catch (e) {
    proxy.$loadingIntern.hide(loadingInstance);

    if (e instanceof ZodError) {
      const zodErrors = e.errors.reduce((acc, error) => {
        acc[error.path[0]] = error.message;
        return acc;
      }, {});
      Object.assign(errors, zodErrors);
      errors.value = zodErrors;
      // console.error('Validation errors:', zodErrors);
    } else {
      console.error("Login failed", e);
    }
  }
};
</script>

<style>
.login-area {
  height: 100dvh;
  display: flex;
  align-items: center;
}
</style>
